<template>
  <div>
    <v-card class="rounded-lg">
      <v-carousel cycle hide-delimiters>
        <v-carousel-item
          v-for="(item, i) in utamas"
          :key="i"
          :src="getIMG(item.berita_thumbnail)"
          :to="'/informasi/berita/' + item.berita_slug"
        >
          <v-row class="fill-height" align="end" justify="center">
            <v-col
              class="subtitle-1 white--text text-center"
              style="background: rgba(0, 0, 0, 0.5)"
            >
              <div class="px-3 font-weight-black">
                {{ item.berita_deskripsi }}
              </div>
            </v-col>
          </v-row>
        </v-carousel-item>
      </v-carousel>
    </v-card>

    <div class="my-10">
      <v-row>
        <v-col cols="12" md="8">
          <v-card
            class="px-5 py-2 mb-5"
            cols="12"
            v-for="item in beritas"
            :key="item.berita_id"
            :to="'/informasi/berita/' + item.berita_slug"
          >
            <v-row>
              <v-col cols="12" md="4" class="my-auto">
                <v-img :src="getIMG(item.berita_thumbnail)"></v-img>
              </v-col>

              <v-col cols="12" md="8" class="mb-3">
                <div>
                  <h2>{{ item.berita_judul }}</h2>
                </div>

                <v-divider class="my-3"></v-divider>

                <div>
                  <span v-html="item.berita_isi.slice(0, 350) + '...'"></span>
                </div>

                <v-divider class="my-3"></v-divider>

                <div>
                  <v-icon small left>mdi-clock-outline</v-icon>
                  <span class="text--disabled">
                    {{ tglFormat(item.berita_created_at) }}
                  </span>
                </div>
              </v-col>
            </v-row>
          </v-card>

          <div class="mt-5">
            <v-pagination v-model="page" :length="totalRows"></v-pagination>
          </div>
        </v-col>

        <v-col
          cols="12"
          md="4"
          style="position: sticky; top: 70px; align-self: start"
        >
          <v-card class="pa-5"> PENGUMUMAN </v-card>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
export default {
  watch: {
    page() {
      this.getBerita();
      window.scrollTo(0, 0);
    },
  },

  data: () => ({
    session: "",

    page: 0,
    totalRows: 0,

    item: {},
    utamas: [],
    beritas: [],
  }),

  async mounted() {
    this.getHot();
    this.getBerita();
  },

  methods: {
    tglFormat(tgl) {
      const date = new Date(tgl);
      const dateTimeFormat = new Intl.DateTimeFormat("id", {
        year: "numeric",
        month: "long",
        day: "2-digit",
      });
      const formatted = dateTimeFormat.formatToParts(date);
      return (
        formatted[0].value + " " + formatted[2].value + " " + formatted[4].value
      );
    },

    getIMG(value) {
      if (value) {
        return process.env.VUE_APP_API_BASE + "upload/beritaGambar/" + value;
      } else {
        return process.env.VUE_APP_API_BASE + "upload/beritaGambar/default.jpg";
      }
    },

    getHot() {
      this.http
        .get(process.env.VUE_APP_API_BASE + "berita/hot")
        .then((res) => {
          this.utamas = res.data.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getBerita() {
      this.http
        .get(process.env.VUE_APP_API_BASE + "berita/public?page=" + this.page)
        .then((res) => {
          this.beritas = res.data.data;
          this.page = res.data.page_number;
          this.totalRows = res.data.page_count;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
